.custom-checkbox {
    .custom-control-label {
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #101010;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        cursor: pointer;
        &::before,
        &::after {
            width: 18px;
            height: 18px;
            top: 0;
            border-radius: 0;
            position: absolute;
            content: "";
            display: block;
            left: -1.5rem;
        }
        &::before {
            pointer-events: none;
            background-color: #bfbfbf;
            border: #adb5bd solid 1px;
        }
        &::after {
            background: no-repeat 50%/50% 50%;
        }
    }
    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            border-color: #101010;
            background-color: #101010;
        }
        &::after {
            background-image: url("~src/assets/icons/checkmark.svg");
        }
    }
    .custom-control-input:checked ~ .focuslabel {
        &::before {
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }
        // &::after {
        //     background-image: url("~src/assets/images/icons/checkbox.svg");
        // }
    }
    .custom-control-input:checked ~ .disablelabel {
        cursor: not-allowed;
        &::before {
            border-color: rgba(0, 0, 0, 0.26);
            background-color: rgba(0, 0, 0, 0.26);
        }
        // &::after {
        //     background-image: url("~src/assets/images/icons/checkbox.svg");
        // }
    }
}
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 1px;
    min-height: 18px;
    user-select: none;

    &.disabled {
        cursor: not-allowed;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            border-color: #101010;
            background-color: #101010;
            &:after {
                display: block;
            }
        }
        &:disabled {
            & ~ .checkmark {
                opacity: 0.5;
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 2px solid #707070;
        border-radius: 2px;
        background-color: #fff;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    // &:hover input ~ .checkmark {
    // }
}

/* The container */
.customradio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:has(input:disabled) {
        opacity: 0.5;
    }
  }
  
  /* Hide the browser's default radio button */
  .customradio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmarkradio {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid #cccccc;
  }
  
  /* On mouse-over, add a grey background color */
  .customradio:hover input ~ .checkmarkradio {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .customradio input:checked ~ .checkmarkradio {
    background-color: #ffffff;
    border: 1px solid #101010;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmarkradio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .customradio input:checked ~ .checkmarkradio:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .customradio .checkmarkradio:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #EB0A1E;
  }