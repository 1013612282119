.dataViewHistoryModal {
    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal-dialog {
        width: 650px;
        max-width: 100%;

        .modal-content {
            background-color: #fff;

            .dataViewHistoryBody {
                height: fit-content;
                align-self: stretch;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 23px;
                padding: 45px 55px;
                justify-content: center;

                .title {
                    text-align: center;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    text-transform: capitalize;
                    margin-bottom: 30px;
                }

                .content {
                    .content-date {
                        text-align: left;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        color: #808080;
                        width: 100%;
                        margin-bottom: 20px;
                        margin-left: 35px;
                    }

                    .order-list {
                        .order-list-item {
                            position: relative;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                            display: flex;
                            color: #232323;
                            word-break: break-word;

                            span {
                                &:nth-of-type(1) {
                                    flex: 1;
                                }

                                &:nth-of-type(2) {
                                    flex-shrink: 0;
                                    padding-left: 15px;
                                    text-align: right;
                                    color: #808080;
                                }
                            }

                            &:not(:last-child) {
                                padding-bottom: 20px;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 7px;
                                    left: 3px;
                                    border: 2px solid #EEE;
                                    height: 100%;
                                }
                            }

                            &::before {
                                content: '';
                                display: inline-block;
                                height: 10px;
                                width: 10px;
                                border-radius: 10px;
                                margin-right: 25px;
                                background-color: #3A3A3A;
                                position: relative;
                                z-index: 1;
                                margin-top: 7px;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

                .action {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;

                    button {
                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.dataLeaveModal,
.dataConfirmProgramRewardModal {
    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal-dialog {
        max-width: 510px;

        .modal-content {
            background-color: #fff;

            .dataLeaveBody {
                height: fit-content;
                align-self: stretch;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 23px;
                padding: 30px 30px 35px;
                justify-content: center;

                div {
                    display: flex;
                    flex-grow: 0;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 40px;
                        height: 40px;
                    }

                    span {
                        height: fit-content;
                        flex-grow: 1;

                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                    }
                }

                .action {
                    flex-direction: row;
                    margin-top: 20px;

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }

                .subtitle {
                    // width: 400px;
                }
            }

            .dataForm {
                padding: 45px 55px;

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                    }
                }

                .action {
                    flex-direction: row;
                    margin-top: 20px;

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.dataRemove {
    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal-dialog {
        width: 510px;
        max-width: 100%;

        .modal-content {
            background-color: #fff;

            .dataForm {
                padding: 45px 55px;

                .content {
                    display: flex;
                    gap: 30px;
                    flex-direction: column;

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 36px;
                        text-transform: capitalize;
                        text-align: center;
                    }

                    .subtitle {
                        text-align: center;
                    }

                    .custom-checkbox {
                        cursor: pointer;

                        .checktext {
                            color: #232323;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px
                        }
                    }
                }

                .action {
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    .deletebtn {
                        background: #EB0A1E;

                        &:hover {
                            background: #930410;
                        }

                        &:active {
                            opacity: 0.4;
                        }
                    }

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}


.dataConfirmForm {
    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal-dialog {
        width: 900px;
        max-width: 100%;
        @media screen and (max-width: 576px) {
           width: auto; 
        }
        .modal-content {
            background-color: #fff;

            .dataForm {
                padding: 45px 55px;

                .content {
                    display: flex;
                    gap: 30px;
                    flex-direction: column;

                    .header-modal {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .back {
                            color: #101010;
                            font-family: Toyota Type;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 11px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            gap: 5px;
                        }
                        .maintitle {
                            flex: 1;
                        }
                    }

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 36px;
                        text-transform: capitalize;
                        text-align: center;
                    }

                    .subtitle {
                        text-align: center;
                    }

                    .custom-checkbox {
                        cursor: pointer;

                        .checktext {
                            color: #232323;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px
                        }
                    }
                }

                .action {
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    .deletebtn {
                        background: #EB0A1E;

                        &:hover {
                            background: #930410;
                        }

                        &:active {
                            opacity: 0.4;
                        }
                    }

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }

            
            .dataImport {
                padding: 45px 55px;

                .content {
                    display: flex;
                    gap: 30px;
                    flex-direction: column;

                    .header-modal {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .back {
                            color: #101010;
                            font-family: Toyota Type;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 11px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            gap: 5px;
                        }
                        .maintitle {
                            flex: 1;
                        }
                    }

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 36px;
                        text-transform: capitalize;
                        text-align: center;
                    }

                    .subtitle-import-file {
                        text-align: center;
                        .ex-file {
                            color: #1a73e8;
                            cursor: pointer;
                            &:hover {
                                color: #3f51b5;
                            }
                        }
                    }

                    .subtitle {
                        text-align: center;
                    }

                    .custom-checkbox {
                        cursor: pointer;

                        .checktext {
                            color: #232323;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px
                        }
                    }
                }

                .action {
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    .deletebtn {
                        background: #EB0A1E;

                        &:hover {
                            background: #930410;
                        }

                        &:active {
                            opacity: 0.4;
                        }
                    }

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.dataUploadModal {
    .modal-dialog {
        max-width: 510px;

        .modal-content {
            display: flex;
            flex-direction: column;
            padding: 40px 50px;
            gap: 25px;

            ly-img-cropper {
                height: 250px;
                background: #eeeeee;

                @media (min-width: $screensmmin) {
                    width: 410px;
                }

                .selectimagesection {
                    display: flex;
                    flex-direction: row;
                    gap: 7px;
                    align-items: baseline;

                    span {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #808080;
                        text-transform: uppercase;
                    }
                }

                ly-cropper-area {
                    box-shadow: 0 0 0 20000px rgb(16 16 16 / 30%);

                    &::before,
                    &::after {
                        border: none;
                    }
                }
            }

            .actionsection {
                display: flex;
                flex-direction: column;
                gap: 50px;

                .buttonsection {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .imageactionsection {
                    width: 75%;
                    align-self: center;

                    .slidersection {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 13px;
                        align-items: center;

                        img {
                            cursor: pointer;
                        }
                    }

                    .imageaction {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        button {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            padding: 0px;
                            gap: 10px;

                            img {
                                height: 20px;
                            }

                            span {
                                text-transform: uppercase;
                            }

                            &:not(:last-child) {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-icon-close {
    text-align: right;
    border: 0;
    justify-content: flex-end;
    padding-bottom: 0;

    img {
        cursor: pointer;
    }
}

.modalMultipleLanguage {
    .modal-dialog {
        width: 600px;
        max-width: 100%;
    }
}

.modalAdjustment,
.modalManualUpdatePoint,
.modalAdjustmentHistory {
    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal-dialog {
        max-width: 510px;

        .modal-content {
            background-color: #fff;

            .dataAdjustment {
                height: fit-content;
                align-self: stretch;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 23px;
                padding: 45px 50px;
                justify-content: center;

                .content-image {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .content-header {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 10px;
                    text-align: center;
                    width: 100%;
                }

                .content {
                    display: flex;
                    gap: 30px;
                    flex-direction: column;

                    .maintitle {
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 36px;
                        text-transform: capitalize;
                        text-align: center;
                    }
                }

                .action {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    justify-content: center;

                    button {
                        width: 150px !important;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }

            }
        }
    }
}

.modalAdjustmentHistory,
.modalManualUpdatePoint {
    .modal-dialog {
        width: auto;
        max-width: 1100px;
    }
}
.mat-datepicker-popup {
    min-height: 400px;
    .mat-datepicker-content-container {
        min-height: 400px;  
    }
}
.mat-datepicker-actions {
    margin-top: auto;
}