.primarybtn {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
  background-color: #3a3a3a;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 120px;

  &:hover {
    background-color: #101010;
  }

  &:active {
    background-color: #808080 !important;
  }

  span {
    flex-grow: 0;

    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #fff !important;
  }

  @media screen and (max-width: 640px) {
    display: block;
    width: 100%;
  }
}

.secondbtn {
  gap: 10px;
  padding: 14px 20px;
  border: 1px solid #101010;
  background-color: #ffffff;
  width: 120px;
  cursor: pointer;

  &:hover {
    background-color: #101010;

    span {
      color: #ffffff;
    }
  }

  &:active {
    background-color: #808080 !important;
  }

  span {
    flex-grow: 0;
    font-size: 12px;
    font-weight: 600;
    color: #101010;
    text-transform: uppercase;
  }
}

.rejectbtn {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 15px;
  width: auto;
  background-color: #EB0A1E;
  border: 1px solid #EB0A1E;

  span {
    flex-grow: 0;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
  }
  &:hover {
    background-color: #DE3535;
  }
  &:active {
    opacity: 0.7;
  }
}

.deletebtn,
.removebtn {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 15px;
  border: 1px solid #f15b55;

  span {
    flex-grow: 0;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.removebtn {
  span {
    color: #f15b55 !important;
  }

  &:hover {
    span {
      color: #ffffff !important;
    }

    background-color: #f15b55;
  }

  &:active {
    opacity: 0.5;
  }
}

button {
  width: fit-content;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 15px;
  border: none;
  background-color: rgba(255, 255, 255, 0);

  span {
    height: 19px;
    flex-grow: 0;

    font-size: 14px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #101010;
  }
}

.primarybtnmodal {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  padding: 0px 20px;
  background-color: #3a3a3a;
  text-transform: uppercase;
  cursor: pointer;
  width: 120px;

  &:hover {
    background-color: #101010;
  }

  &:active {
    background-color: #808080 !important;
  }

  span {
    flex-grow: 0;

    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }
}

.disabledbtn {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
  background-color: #ccc;
  text-transform: uppercase;
  width: 120px;

  &:hover {
    background-color: #ccc;
  }

  &:active {
    background-color: #ccc !important;
  }

  span {
    flex-grow: 0;

    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }
}

button {
  &.primary-reward-store {
    color: $btn-primary-bg;
  }
}
