@font-face {
    font-family: 'Toyota Type';
    src: url('../fonts/ToyotaType-Light.ttf');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Toyota Type';
    src: url('../fonts/ToyotaType-Regular.ttf');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Toyota Type';
    src: url('../fonts/ToyotaType-Semibold.ttf');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Toyota Type';
    src: url('../fonts/ToyotaType-Semibold.ttf');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Toyota Type';
    src: url('../fonts/ToyotaType-Bold.ttf');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}